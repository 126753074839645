// These JavaScript module imports need to be bundled:
import { clientsClaim } from "workbox-core";
import { precacheAndRoute, cleanupOutdatedCaches } from "workbox-precaching";

// Note: Ignore the error that Glitch raises about workbox being undefined.
self.skipWaiting();
clientsClaim();

// Add Precache Routes
precacheAndRoute(self.__WB_MANIFEST);

// Cleanup Workbox Old Caches
cleanupOutdatedCaches();
